<template>
<div v-if="queryMark" :class="['deliverDetail', canOperate ? 'deliverDetail_mg' : '']">
  <!-- 交付状态 -->
  <div class="step">
    <Step :detail="deliverDetail"></Step>
  </div>
  <!-- 被驳回的信息 -->
  <div v-if="showReject" class="reject-info">
    <span>{{ showReject.type===1?$t('【提车确认书线下签署】确认书'):$t('车辆状况告知书') }} {{ $t('被驳回,请重新上传') }}</span>
    <span @click="toRejectDetails(showReject)">
      {{ $t('上传') }}
      <van-icon name="arrow"/>
    </span>
  </div>
  <!-- 被锁定 -->
  <!-- lockStatus 锁定状态 0未定 2质损 4 冻结 6都  -->
  <!-- portalLockStatus 总部锁定 0未锁定 1锁定 2解锁 -->
  <div v-if="isDcosLocked" class="reject-info">
    <span>{{ $t('当前交付单的') }}<span style="font-weight: bold">{{ $t('车辆被锁定') }}</span></span>
    <span @click="toLockDetails('dcos')">
      {{ $t('详情查看') }}
      <van-icon name="arrow"/>
    </span>
  </div>
  <div v-if="isManageLocked" class="reject-info">
    <span>{{ $t('当前交付单被') }}<span style="font-weight: bold">{{ $t('总部锁定') }}</span></span>
    <span @click="toLockDetails('portal')">
      {{ $t('详情查看') }}
      <van-icon name="arrow"/>
    </span>
  </div>
  <!-- 解除锁定，但是需要上传车辆状态告知书 -->
  <div v-if="isUnLocked" class="reject-info">
    <span>{{ $t('当前交付单存在风险，需签署车辆状态告知书') }}</span>
    <span @click="toVehicleStatus">
      {{ $t('上传') }}
      <van-icon name="arrow"/>
    </span>
  </div>
  <div class="deliverItem">
    <DeliverBasicInfo :details="deliverDetail"/>
  </div>
  <!-- 转交付备注 -->
  <div v-if="deliverDetail.escrowRemark" class="middle deliverItem">
    <div class="common-t">
      <div class="common-t-l">{{$t('销售顾问交接备注')}}</div>
    </div>
    <div class="remark">{{ deliverDetail.escrowRemark }}</div>
  </div>
  
  <!-- deliverDetail.deliveryTime有值 说明客户完成提车确认书签署，则不展示该卡片 -->
  <div v-if="deliverDetail?.bookRes&&!deliverDetail.deliveryTime" class="middle deliverItem">
    <div class="common-t">
      <div class="common-t-l">{{$t('交付时间预约')}}</div>
    </div>
    <div class="deliverCarInfo">
      <div class="info">
        <div v-if="deliverDetail.deliveryMethod">{{ $t('交付方式') }}: {{ deliverDetail.deliveryMethod | codeFormat}} </div>
        <div v-if="isShowRemoteAddress">{{ $t('客户详细地址') }}: {{ remoteDeliverAddress  }}</div>
        <div>{{ $t('预约时间') }}: {{ `${deliverDetail.bookRes.bookDate} ${deliverDetail.bookRes.bookTimeStart}~${deliverDetail.bookRes.bookTimeEnd}` }}</div>
      </div>
      <div class="operate">
        <span v-if="isDeliverSalesOwner" @click="modifyBook(deliverDetail.bookRes)">{{$t('修改时间')}}</span>
      </div>
    </div>
  </div>
  <!-- 车辆交付提醒 -->
  <div v-if="deliverDetail.deliveryTime && deliverDetail.deliveryStatus !== '4000008'" class="deliverItem deliverTips">
    <div class="common-t">
      <div class="common-t-l">{{$t('车辆交付提醒')}}</div>
    </div>
    <div class="tipsContent">
      <p>{{ $t('交付单关联车辆已经与') + ` ${deliverDetail.deliveryTime} ` + $t('客户完成交付。') }} </p>
      <p>{{ $t('请确认是否已经完成交付动作') }}</p>
    </div>
    <div v-if="userInfo.type == 1" class="tipConfirm" @click="tipConfirm">{{ $t('确认完成交付') }}</div>
  </div>
  
  <div class="bottom deliverItem">
    <van-tabs v-model="activeName" :ellipsis="false">
      <van-tab v-for="(item, index) in tabList" :key="index" :title="item.title" :name="item.componentName">
        <component :is="item.componentName" v-if="activeName === item.componentName"
          :id="deliverDetail.leadId"
          :canTagEdit="false"
          :orderDetail="deliverDetail"
          :isDeliverSalesOwner="isDeliverSalesOwner" class="scrollBlock" :detail="deliverDetail" @update="getDetail"></component>
      </van-tab>
    </van-tabs>
  </div>
  <div v-if="canOperate" class="common-footer footer">
    <template>
      <div style="flex: 1; display: flex; align-items: center;">
        <!-- 客户临时授权 -->
        <van-popover v-if="isDeliverShopManage || (userInfo.roleLogos.includes('1014006')&&userInfo.id===deliverDetail.deliveryUserId)" 
          v-model="showPopver" theme="dark" trigger="click" placement="top-start" :actions="actions"
          @select="operate">
          <div class="van-popover__content">
            <template v-for="(item, index) in actions">
              <div :key="index" class="van-popover__action" @click.stop="operate(item)">
                <div class="van-popover__action-text van-hairline--bottom">{{ item.text }}</div>
              </div>
            </template>
          </div>
          <template #reference>
            <div class="more">{{$t('更多')}}</div>
          </template>
        </van-popover>
        <!-- 跟进 -->
        <div v-if="isDeliverSalesOwner" class="follow" @click.stop="operate({ key: 'follow' })">{{$t('跟进记录填写')}}</div>
      </div>
      <div v-if="isDeliverSalesOwner" class="icons">
        <externalUserIcon v-if="!deliverDetail.customerWeChat" :id="deliverDetail.id" class="icon"
          @selectUser="selectUser" />
        <qwIcon v-if="deliverDetail.customerWeChat" :leadId="deliverDetail.leadId" :externalUserIds="deliverDetail.customerWeChat" class="icon" />
        <wxIcon v-if="!deliverDetail.customerWeChat" class="icon" />
        <phoneIcon :phone="deliverDetail.customerMobile" class="icon" />
      </div>
    </template>
    <template v-if="isDeliverShopManage && !isDeliverSalesOwner">
      <div class="footer-l">
        <span class="title">{{$t('交付专员：')}}</span>
        <span class="deliverName">{{ temDeliverInfo.deliveryUserName || temDeliverInfo.deliveryPreUserName || $t('暂无')
        }}</span>
        <span>{{ !temDeliverInfo.deliveryUserName && temDeliverInfo.deliveryPreUserName ? `(${$t('预分配')})` : '' }}</span>
      </div>
      <div class="footer-r">
        <span class="sales" @click="selectSales">{{ (temDeliverInfo.deliveryUserId != '0' ||
          temDeliverInfo.deliveryPreUserId != '0') ? $t('变更顾问') : $t('选择顾问') }}</span>
        <div class="follow" @click.stop="assign(2)">{{$t('确认分配')}}</div>
      </div>
    </template>
  </div>
  <van-dialog v-model="showDialog" class="authDialog" :title="$t('临时授权')" show-cancel-button confirmButtonColor="#EED484"
    :confirm-button-text="$t('确定')" :before-close="beforeClose">
    <van-form ref="dialogForm" label-width='90px' validate-trigger="onSubmit">
      <van-field v-model="authParams.authedDeliveryUserName" :label="$t('授权人员')" readonly :placeholder="$t('请选择授权人员')" required
        :rules="[{ required: true }]" right-icon="arrow" @click="onSelectPerson" />
      <van-field v-model="authParams.rangeTime" :label="$t('授权期限')" readonly :placeholder="$t('请选择授权期限')" required
        :rules="[{ required: true }]" right-icon="arrow" @click="onSelectAuthTime" />
    </van-form>
  </van-dialog>
  <!-- 确认客户是否到店 -->
  <van-dialog v-model="showInStore" class="is-confirm-instore" :title="$t('提车时间变更提醒')" show-cancel-button confirmButtonColor="#EED484"
    :confirm-button-text="$t('重新排程')" :beforeClose="confirmInStore">
    <div class="tips">{{$t('客户提车时间已超期，在变更排程前，请确认客户是否已到店')}}</div>
    <van-radio-group v-model="arriveFlag" direction="horizontal" class="radio-group">
      <van-radio :name="0">{{$t('客户未到店')}}</van-radio>
      <van-radio :name="1">{{$t('客户到店')}}</van-radio>
    </van-radio-group>
  </van-dialog>
  <!-- 员工列表面板 -->
  <BusinessFilters v-if="showDistribution" ref="filter" v-model="showDistribution" :options="distributionOptions"
    :defaultActiveKey="['deliverIdList']" :buttonNameReset="$t('取消')" @reset="showDistribution = false"
    @submit="distributionFilter">
  </BusinessFilters>
  <van-calendar v-model="showCalendar" :min-date="minDate" :max-date="maxDate" type="range" @confirm="onConfirm" />
  <van-popup v-model="showBatch">
    <BatchVipOrderSelect v-if="showBatch" 
    :current-deliver="deliverDetail"
    :deliverList="deliverList"
    :title="''"
    :subTitle="`同一合同下还有以下${deliverList.length}个交付单可以完成交付，可操作批量`"
    :confirmBtnText="'确认完成交付'"
    :footerBtnShow="true"
    @confirm="onBatchSelect" 
    @cancel="showBatch = false"/>
  </van-popup>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DeliverInfo from './component/deliverInfo'
import deliveryServices from '@/services/deliveryServices.js'
import Step from './component/step'
// import SpecialAttention from './component/SpecialAttention.vue'
import CustomerInfo from '@/modules/order/component/customerInfo'
import Concerns from './component/Concerns'
import Track from './component/track.vue'
// import DeliverTrack from './component/DeliverTrack'
// import FolloTrack from './component/FolloTrack'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import loading from '@/utils/loading'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import dayjs from 'dayjs'
import { dateFormat } from '@/utils'
import Tags from '@/modules/clue/component/tags/index.vue'
import clueServices from '@/services/clueServices'
import OrderInfo from './component/orderInfo.vue'
import orderServices from '@/services/orderServices'
import DeliverBasicInfo from './component/deliver-basic-info.vue'
import mixin from './mixin'
import BatchVipOrderSelect from './component/batch-vip-order-select.vue'
let vm
export default {
  name: 'deliverDetail',
  components: { DeliverInfo, CustomerInfo, Step, Concerns, Track, externalUserIcon, phoneIcon, qwIcon, wxIcon, BusinessFilters,Tags,OrderInfo,DeliverBasicInfo, BatchVipOrderSelect },
  mixins:[mixin],
  data() {
    return {
      queryMark: false,
      deliverDetail: {},
      // orderDetail: {},
      activeName: 'Concerns',
      tabList: [
        { componentName: 'Concerns', title: vm.$t('交付任务') },
        { componentName: 'OrderInfo', title: vm.$t('订单信息') },
        { componentName: 'Track', title: vm.$t('交付轨迹') },
        // { componentName: 'FolloTrack', title: vm.$t('跟进轨迹') },
        { componentName: 'Tags', title: vm.$t('标签') }
      ],
      showPopver: false,
      showDialog: false,
      showDistribution: false,
      showCalendar: false,
      minDate: new Date(dayjs()),
      maxDate: new Date(dayjs().add(3, 'years')),
      // 授权参数
      authParams: {
        authedDeliveryUserId: '',
        authedDeliveryUserName: '',
        authStartTime: '',
        authEndTime: '',
        orderId: '',
        rangeTime: '',
      },
      // 分配参数
      distributeParams: {
        deliveryUserId: '',
        deliveryUserName: '',
        orderIds: [],
        type: 2
      },
      authorDistribute: 1, // 授权 或者分配
      temDeliverInfo: {},
      showInStore: false,
      arriveFlag: undefined,
      deliveryTime: '2024-04-11 13:29:28',
      requestLoading: false,
      showBatch: false,
      deliverList: [],
      selectIds: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    distributionOptions() {
      const that = this
      return [{
        name: '',
        type: 'B_PEOPLE_SELECTION',
        field: 'deliverIdList',
        isSelection: true,
        valueType: 'object',
        shopIds: '',
        height: '80vh',
        roleCodes: '1014006',
        filterData(data) {
          return that.getFilterData(data)
        } // 交付专员的角色
      }]
    },
    // 更多模块 操作按钮
    actions() {
      const { bookRes, deliveryTime, deliveryStatus, applyRefund, refund } = this.deliverDetail || {}
      let arr = []
      if (!refund) {
        arr.push({ text: this.$t('客户临时授权'), key: 'tempAuth' })
      }
      if (this.isDeliverSalesOwner) {
        // deliverDetail.bookRes.bookDate
        arr.push({ text: this.$t('送车上门申请'), key: 'remoteDelivery' })
        // arr.push({ text: this.$t('异常订单处理'), key: 'orderProcess' })
        arr.push({ text: this.$t('更多协议签署'), key: 'protocolsUpload' })
      }
      if (bookRes && !deliveryTime) {
        // 交付确认书签署前都可以取消
        arr.unshift({ text: this.$t('取消提车预约'), key: 'cancelCarAppointment' })
      }
      if (this.isDeliverSalesOwner && !['4000000','4000001','4000002','4000003'].includes(deliveryStatus)) {
        arr.push({ text: this.$t('预约到店验车'), key: 'bookInspection' })
      }
      // 申请退单 交付经理
      if (applyRefund && this.isDeliverShopManage) {
        arr.push({ text: this.$t('申请退单'), key: 'returnOrder' })
      }
      if (refund && this.isDeliverShopManage) {
        arr.push({ text: this.$t('查看退单申请'), key: 'viewReturnOrder' })
      }
      return arr
    },
    // 交付经理
    isDeliverShopManage() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.includes('1014007')
    },
    // 交付专员或者是被授权顾问 且是自己的数据
    isDeliverSalesOwner() {
      const { roleLogos = [], id } = this.userInfo
      return roleLogos.includes('1014006') && (this.deliverDetail.deliveryUserId == id || this.deliverDetail.authedDeliveryUserId == id)
    },
    // 是否是待分配数据(包含了预分配)
    isWaitDeliverOwner() {
      const { deliveryUserId } = this.deliverDetail
      return deliveryUserId == '0'
    },
    canOperate() {
      // 交付专员 且是自己的数据
      // 交付店长 已经分配的数据
      // 交付店长 未分配的数据
      return this.isDeliverShopManage || this.isDeliverSalesOwner
    },
    showReject(){
      // 只有被驳回时才展示  status 1待审核 5通过 10拒绝
      const target = (this.deliverDetail?.review || []).find(e=>e.status===10)
      return target
    },
    // dcos锁定
    isDcosLocked(){
      return [2,4,6].includes(this.deliverDetail?.lockStatus)
    },
    // 总部锁定
    isManageLocked(){
      return [1].includes(this.deliverDetail?.portalLockStatus)
    },
    // 质损 && 没有车辆状态告知书
    // lossStatus：质损状态 1质损 0未质损 
    // isExhibition 1为展车
    // PDI 4004001未通过
    isUnLocked(){
      const { lossStatus,isExhibition, pdiStatus } = this.deliverDetail
      const { lossNoticeUrl } = this.deliverDetail?.extend || {}
      const target = (this.deliverDetail?.review || []).find(e=>e.type===2)
      return (lossStatus===1 || isExhibition===1 || pdiStatus === '4004001') && !lossNoticeUrl && !this.isLocked && !target
    }
  },
  watch: {},
  beforeCreate(){
    vm = this
  },
  mounted() {
    this.authParams.orderId = this.$route.query.id
    this.distributeParams.orderIds = [this.$route.query.id]
    this.getDetail()
    // this.getOrderDetail()
  },
  methods: {
    // 过滤可选择顾问
    getFilterData(data) {
      const { deliveryUserId, deliveryPreUserId } = this.temDeliverInfo
      const id = deliveryUserId != '0' ? deliveryUserId : deliveryPreUserId
      for (const key in data) {
        const child = data[key]
        child.map((item, index) => {
          if (item.id === id) {
            child.splice(index, 1)
          }
        })
        if (child.length === 0) {
          delete data[key]
        }
      }
      return data
    },
    // 获取交车详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.$route.query.id })
      this.queryMark = true
      this.deliverDetail = res || {}
      const { deliveryUserId = '', deliveryUserName = '', deliveryPreUserId = '', deliveryPreUserName = '' } = this.deliverDetail
      this.temDeliverInfo = { deliveryUserId, deliveryUserName, deliveryPreUserId, deliveryPreUserName }
      this.distributeParams.deliveryUserId = deliveryPreUserId
      this.distributeParams.deliveryUserName = deliveryPreUserName
      if (this.deliverDetail.bookRes) {
        const { bookTimeEnd, bookTimeStart } = this.deliverDetail.bookRes
        if (bookTimeStart && bookTimeStart.indexOf('-') > -1) this.deliverDetail.bookRes.bookTimeStart = dateFormat(bookTimeStart,'HH:mm')
        if (bookTimeEnd && bookTimeEnd.indexOf('-') > -1) this.deliverDetail.bookRes.bookTimeEnd = dateFormat(bookTimeEnd,'HH:mm')
      }
    },
    // 获取订单详情
    async getOrderDetail() {
      const res = await orderServices.getOrderDetail({ id: this.$route.query.id })
      this.orderDetail = res
    },
    selectUser(params = {}) {
      const { externalUserid, name } = params
      this.$set(this.deliverDetail, 'customerName', name)
      this.$set(this.dataSource, 'customerWeChat', externalUserid)
    },
    // 更多
    more() {
      this.showPopver = true
    },
    operate({ key }) {
      this.showPopver = false
      const obj = {
        follow: '/deliver-follow',
        bookInspection: '/bookInspection',
        protocolsUpload: '/protocolsUpload',
        remoteDelivery: '/remoteDelivery'
      }
      if (key === 'tempAuth') {
        this.showDialog = true
      } else if (obj[key]) {
        this.$router.push({
          path: obj[key],
          query: {
            id: this.$route.query.id
          }
        })
      } else if (key === 'cancelCarAppointment') {
        this.onCancel()
      } else if (['returnOrder', 'viewReturnOrder'].includes(key)) {
        // 申请退单
        const { id } = this.deliverDetail
        const query = {
          id,
        }
        this.$router.push({
          path:'/refundOrder',
          query
        })
      } else {
        this.$toast('功能开发中。。')
      }
    },
    onSelectPerson() {
      this.authorDistribute = 1
      this.showDistribution = true
    },
    onSelectAuthTime() {
      this.showCalendar = true
    },
    distributionFilter({ deliverIdList }) {
      if (deliverIdList.length) {
        const { name, value } = deliverIdList[0]
        if (this.authorDistribute === 1) {
          this.authParams.authedDeliveryUserId = value
          this.authParams.authedDeliveryUserName = name
        } else {
          // 更新展示数据
          this.temDeliverInfo.deliveryUserId = value
          this.temDeliverInfo.deliveryUserName = name
          // 组装入参
          this.distributeParams.deliveryUserId = value
          this.distributeParams.deliveryUserName = name
          // 直接分配给选中的交付专员
          this.assign(1)
        }
      } else {
        this.authParams.authedDeliveryUserId = ''
        this.authParams.authedDeliveryUserName = ''
        this.$toast(this.authorDistribute === 1 ? this.$t('请选择授权人员') : this.$t('请选择分配人员'))
      }
    },
    onConfirm(data) {
      if (data[0] && data[1]) {
        this.authParams.authStartTime = dayjs(data[0]).format('YYYY-MM-DD 00:00:00')
        this.authParams.authEndTime = dayjs(data[1]).format('YYYY-MM-DD 23:59:59')
        this.authParams.rangeTime = `${dayjs(data[0]).format('YYYY-MM-DD')}~${dayjs(data[1]).format('YYYY-MM-DD')}`
      }
      this.showCalendar = false
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.$refs.dialogForm.validate().then(async () => {
          loading.showLoading()
          await deliveryServices.deliveryUserAuth(this.authParams)
          loading.hideLoading()
          this.$toast.success(this.$t('操作成功！'))
          setTimeout(() => {
            this.$router.go(-1)
            done()
          }, 1500)
        }, done.bind(null, false))
      } else {
        this.authParams = this.$options.data().authParams
        this.showDialog = false
        done()
      }
    },
    // 选择顾问
    selectSales() {
      // 需要过滤掉预分配的顾问数据
      this.authorDistribute = 2
      this.showDistribution = true
    },
    // 确认分配
    async assign(type) {
      if (!this.distributeParams.deliveryUserId || this.distributeParams.deliveryUserId == '0') {
        this.$toast(this.$t('请选择交付专员！'))
        return false
      }
      loading.showLoading()
      if (type) this.distributeParams.type = type
      const res = await deliveryServices.deliverAllocationPost(this.distributeParams)
      this.$toast.success(this.$t('操作成功'))
      setTimeout(() => {
        this.$router.go(-1)
      }, 2000)
    },
    // 修改交车信息
    modifyBook({ deliveryTaskId: taskId, bookDate, bookTimeEnd, bookTimeStart }) {
      // 如果预约时间已过期，需要二次提示
      if (dayjs().isAfter(dayjs(`${bookDate} ${bookTimeEnd}:59`))){
        this.showInStore = true
        return
      }
      this.scheduling({ deliveryTaskId: taskId, bookDate, bookTimeEnd, bookTimeStart })
    },
    confirmInStore(action,done){
      if (action==='cancel'){
        done()
        return
      }
      if (![0,1].includes(this.arriveFlag)&&action==='confirm'){
        this.$toast(this.$t('请选择'))
        done(false)
        return
      }
      this.scheduling(this.deliverDetail.bookRes,this.arriveFlag)
    },
    // 去排程
    scheduling({ deliveryTaskId: taskId, bookDate, bookTimeEnd, bookTimeStart },arriveFlag){
      const { appOrderId, dcosOrderId, orderType,bookDeliveryTime, carOrderMaterial: { seriesName, modelName, colourName,interiorName }, vin, carRegistration:{ ownerName } } = this.deliverDetail
      this.$router.push({
        path: '/pickCarScheduling',
        query: {
          appOrderId:orderType===3?appOrderId:dcosOrderId,
          seriesName,
          modelName,
          vin,
          id: this.$route.query.id,
          taskId,
          bookDate,
          bookTimeStart,
          bookTimeEnd,
          arriveFlag,
          ownerName,
          colourName,
          interiorName,
          bookDeliveryTime
        }
      })
    },
    // 确认完成交付
    async tipConfirm() {
      const { lossStatus,isExhibition } = this.deliverDetail
      const { lossNoticeUrl } = this.deliverDetail?.extend || {}
      const { einvoiceUrl } = this.deliverDetail?.invoice || {}
      const target = (this.deliverDetail?.review || []).find(e=>e.type===1)
      const { type,status } = target|| {}
      if (!einvoiceUrl) return this.$toast(this.$t('请上传发票后，再确认完成交付'))
      // 如果是质损车或者展车，需要上传车辆状态告知书
      if ((lossStatus===1 || isExhibition===1) && !lossNoticeUrl) return this.$toast(this.$t('请上传车辆状态告知书后，再确认完成交付'))

      // 线下提车确认书需要审核通过
      if (type===1&&[1,10].includes(status)){
        return this.$toast(this.$t('请先完成线下提车确认书审核，再确认完成交付'))
      }
      // 大客户时判断是否有同批次交车单
      if ([1,2,5].includes(this.deliverDetail.orderType)){
        const res = await deliveryServices.getBatchOrder({ orderId: this.deliverDetail.id, type:6 }) 
        if (res.length){
          this.deliverList = res
          this.showBatch = true
          return
        }
      }
      this.requestSubmit()
    },
    onBatchSelect(selectIds){
      this.selectIds = selectIds
      // 请求提交
      this.requestSubmit()
    },
    async requestSubmit(){
      loading.showLoading()
      if (this.requestLoading) return false
      this.requestLoading = true
      try {
        const result = await deliveryServices.deliveryConfirmBatch([this.$route.query.id, ...this.selectIds])
        loading.hideLoading()
        this.requestLoading = false
        const { code, msg } = result || {}
        if (code===0){
          this.$toast.success(this.$t('操作成功！'))
          setTimeout(() => {
            this.getDetail()
          }, 1000)
        } else {
          this.$toast.fail(msg|| '请联系管理员')
        }
      } catch {
        this.requestLoading = false
        loading.hideLoading()
      }
    },
    // 取消预约交车
    onCancel(){
      this.$dialog
        .confirm({
          title: this.$t('取消预约'),
          message: this.$t('确认取消当前客户的预约提车？'),
          confirmButtonColor: '#B9921A',
          cancelButtonText: '返回'
        })
        .then(async () => {
          loading.showLoading()
          const params = {
            id: this.deliverDetail.bookRes.deliveryTaskId,
            taskStatus: '4004001',
            executionJson: {
              taskCode: 'DELIVERY_BOOK',
              status: 0
            }
          }
          const res = await deliveryServices.deliveryExecution(params)
          const { code, msg, message } = res
          if (code === 0) {
            loading.hideLoading()
            this.$toast.success(this.$t('操作成功！'))
            const that = this
            setTimeout(() => {
              that.getDetail()
            }, 500)
          } else {
            loading.hideLoading()
            this.$toast(msg || message)
          }
        })
    },
    toRejectDetails(target){
      // 上传
      const { refuseReason,reviewTime,type } = target
      const path = '/abnormalProcessApprove/details'
      const query = {
        isReject: true,
        refuseReason,
        reviewTime,
        type,
        orderId: this.$route.query.id
      }
      this.$router.push({
        path,
        query
      })
    },
    toLockDetails(type){
      const { seriesName,modelName,colourName,interiorName } = this.deliverDetail.carOrderMaterial
      const { vin } = this.deliverDetail
      this.$router.push({
        path: '/deliverLockDetails',
        query: {
          orderId: this.$route.query.id,
          seriesName,modelName,colourName,interiorName,vin,type
        }
      })
    },
    // 车辆状态告知书上传
    toVehicleStatus(){
      const path = '/abnormalProcessApprove/details'
      const query = {
        isReject: false,
        isUploadStatus: true,
        type: 2,
        orderId: this.$route.query.id,
        displayStatus: 2
      }
      this.$router.push({
        path,
        query
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-popover__action {
  min-width: 128px;
  width: auto;
}
.deliverDetail {
  position: relative;
  padding: 77px 16px 0;
  display: flex;
  flex-direction: column;
  color: #0D171A;
  font-size: 13px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  &.deliverDetail_mg {
    padding-bottom: 96px;

    .bottom {
      // height: calc(100vh - 189px);

      // .scrollBlock {
      //   height: calc(100vh - 257px);
      //   overflow-y: scroll;
      // }
    }
  }

  /deep/ .van-steps--horizontal {
    padding: 10px 0 0 0;
  }

  /deep/ .van-cell {
    &:not(.van-collapse-item__title) {
      .van-cell__title {
        max-width: 120px;
        flex: inherit;
      }
    }
  }

  /deep/ .authDialog {
    .van-cell::after {
      display: none;
    }
  }

  /deep/ .is-confirm-instore{
    padding: 16px 16px 0;
    .tips{
      padding-top: 8px;
      color: #646566;
      font-size: 14px;
    }
    .radio-group{
      padding: 16px 0;
      display: flex;
      justify-content: space-between;

      .van-radio__label{
        color: #646566;
        font-size: 14px;
      }
    }
  }

  /deep/ .van-calendar__footer {
    .van-button--danger {
      background: @yellow;
      color: @black;
      border-color: @yellow;
    }
  }

  .reject-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: red !important;
    color: #FFFFFF;
    padding: 8px 16px;
    border-radius: 8px;
    margin: 8px 0;
    font-size: 14px;
  }

  .deliverItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    width: 100%;
    box-sizing: border-box;

    .remark{
      font-size: 14px;
      line-height: 18px;
      padding-top: 10px;
      word-wrap: break-word;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .deliverCarInfo {
      display: flex;
      flex-direction: column;

      .info {
        div {
          margin-top: 8px;
        }
      }

      .operate {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        color: #FF7F50;

        div {
          flex: 1;
          text-align: center;
          color: @yellow-text;
          // &:last-of-type{
          //   position: relative;
          //   &::before{
          //     content: ' ';
          //     left: 0;
          //     position: absolute;
          //     top: calc(50% - 6px);
          //     width: 2px;
          //     height: 12px;
          //     background: #D9D9D9;
          //     border-radius: 0px 0px 0px 0px;
          //   }
          // }
        }
      }
    }
  }

  .top {
    padding: 12px;
  }
  .deliverTips{
    padding:0 12px 12px;
    .common-t{
      border: none
    }
    .tipConfirm{
      margin-top: 12px;
      width: 100%;
      text-align: right;
      color: @yellow-text;
      cursor: pointer
    }
  }
  .middle {
    padding: 0 12px 12px;

    /deep/ .van-cell {
      padding: 10px 0;

      &::after {
        width: 100%;
        left: 0;
      }
    }
  }

  .step {
    position: fixed;
    top: 0;
    max-width: 500px;
    width: 100%;
    margin-left: -16px;
    z-index: 999;
    padding: 0 12px;
    background: #fff;
    height: 61px;
    overflow-y: hidden;
  }

  .bottom {
    padding: 12px;
    // height: calc(100vh - 16px - env(safe-area-inset-bottom));

    // .scrollBlock {
    //   height: calc(100vh - 100px - env(safe-area-inset-bottom));
    //   overflow-y: scroll;
    // }
    /deep/ .van-tabs--line>.van-tabs__wrap {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    }
  }

  .common-footer {
    display: flex;
    font-size: 14px;

    .footer-l {
      display: flex;
      margin-right: 10px;

      .title {
        color: rgba(13, 23, 26, 0.45);
      }

      .deliverName {
        max-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .footer-r {
      display: flex;
      align-items: center;

      .sales {
        color: #0040C5;
        cursor: pointer;
      }
    }

    .more {
      color: @black;
      cursor: pointer;
    }

    .follow {
      height: 36px;
      padding: 0 16px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #EED484;
      border-radius: 100px;
      color: @black;
      cursor: pointer;
    }

    .icons {
      display: flex;
      padding: 8px 0 12px 0;
      justify-content: space-between;

      .icon {
        margin-left: 16px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}</style>
