<template>
<div :class="['stepComponent', isEn ? 'stepComponent-en' : '']">
  <van-steps :active="activeStep">
    <van-step v-for="(item, index) in stepList" :key="item.code">{{ item.name }}</van-step>
  </van-steps>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { i18n } from '@/main'
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      stepList: [
        {
          name: this.$t('未排产'),
          code: '4000000'
        },
        {
          name: this.$t('已排产'),
          code: '4000001' 
        },
        {
          name: this.$t('已下线'),
          code: '4000002' 
        },
        {
          name: this.$t('已发运'),
          code: '4000003' 
        },
        {
          name: this.$t('已入库'),
          code: '4000004' 
        },
        {
          name: this.$t('已PDI'),
          code: '4000005' 
        },
        {
          name: this.$t('已整备'),
          code: '4000010' 
        },
        {
          name: this.$t('已交车'),
          code: '4000008' 
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['dictHash']),
    // stepList() {
    //   let arr = this.dictHash[4000] || []
    //   return arr.filter(({ code }) => code != 4000000)
    // },
    activeStep() {
      // 交车预约属于‘已PDI’节点
      if (this.detail.deliveryStatus==='4000006'){
        return this.stepList.findIndex(({ code }) => code == '4000005')
      }
      return this.stepList.findIndex(({ code }) => code == this.detail.deliveryStatus)
    },
    isEn() {
      return i18n.locale === 'en'
    }
  },
  watch: {

  },
  mounted() {
    // // 获取滚动容器
    const idx = this.stepList.findIndex(({ code }) => code == this.detail.deliveryStatus)
    const scrollContainer = document.querySelector('.van-steps')
    if (idx>=3){
      scrollContainer.scrollLeft = idx * 70
    }

  },
  methods: {}
}
</script>
<style lang="less" scoped>
.stepComponent{
  overflow: hidden;
  .title{
    display: flex;
    height: 48px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(13,23,26,0.05);
    justify-content: space-between;
    padding-left: 8px;
    font-size: 16px;
    position: relative;
    &::before{
      content: ' ';
      left: 0;
      position: absolute;
      top: calc(50% - 6px);
      width: 2px;
      height: 12px;
      background: @yellow-text;
      border-radius: 0px 0px 0px 0px;
    }
  }
  &.stepComponent-en{
    /deep/.van-steps{
      overflow-x: scroll;
      .van-steps__items{
        width: 1300px;
      }
    }
  }
  /deep/.van-steps{
    overflow-x: scroll;
    .van-steps__items{
      width: 450px;
      .van-step--horizontal{
        .van-step__icon{
          font-size: 20px;
        }
      }
    }
  }
}
</style>
